import React from 'react'
import fo1 from '../fo1.JPG';
import fo2 from '../fo2.JPG';
import fo3 from '../fo3.JPG';
import fo4 from '../fo4.JPG';
import fo5 from '../fo5.JPG';
import fo6 from '../fo6.JPG';
import fo7 from '../fo7.JPG';
import fo8 from '../fo8.JPG';
import fo9 from '../fo9.JPG';
import { useState } from "react";





const Hosanna = () => {
  const [basicModal1, setBasicModal1] = useState(false);
  const toggleShow1 = () => setBasicModal1(!basicModal1);
 
  
  return (
    <div>
  <div className='row mt-9p container mx-auto'>
    <div className='col-sm-6'>
      <div className='category-home mt-3 mb-1 h-fitcont'>Health</div>

      <h1 className='c-red text-left fw-semibold ff-goth'>CO-ACTION PRESCHOOL FOR THE DEAF</h1>
      <div className='c-primary ff-goth'>SPONSORSHIP 2022/23 SCHOOL YEAR</div>
      <div className='mt-4 fs-9'>
      The Co-Action School is a preschool for deaf children. It was founded by the Ethiopian National Association for the Deaf (ENAD). The school building was purchased through a donation from UNESCO, hence the name of the school was derived from the UNESCO program.<br className='mb-1'></br>
The UNESCO co-action program, in collaboration with various donors, raised money and donated it to ENAD. To commemorate this charity, the name of the schools’ co-Action program was given. The school has served the deaf community for over 30 years.<br className='mb-1'></br>
The school is managed by ENAD and currently has two teachers, two councillors, a sign language interpreter and a security guard. All wages are currently funded through donations.<br className='mb-1'></br>
      </div>
      <a href='https://chapa.link/donation/view/DN-PDSCuQCG2vi3'>
                            <div className='b-blue c-white px-3 py-2 text-center br-5p w-fit my-2'>Donate Now</div>
                        </a>
    
    </div>
    <div className='col-sm-6'>
      <img src={fo6} alt="Logo" className='w-100 my-3 mt-5'/> 
    </div>
    </div>
    <div className='container row m-auto my-3'>
      <div className='col-sm-6'>
        <div className='fs-9'>
        Most of the students are 4 years old and older. Almost all are deaf and some are hearing impaired. They all come from far away, some even from the countryside and the suburbs of Addis Ababa. During school weeks, they live with relatives in the city. This is the only way they can get access to education. For the families of our deaf children it is already difficult to cover their basic needs, so that the transportation costs to the institution alone are a considerable challenge for them. Again and again leads to the termination of the schooling. For this unfortunate reason, about 3/4 of all deaf children drop out of school during the school year.      
        </div>
        <div className='fs-9 my-3'>
        The Dr. Negaso Gidada Foundation has been supporting the school with a meal program since the 2021/22 school year. This ensures that both students and staff receive breakfast and lunch at the school daily. The meals are prepared on site.      </div>
        <div className='fs-9 mb-3'>
            In addition, workshops for the pupils’ parents are also funded by the foundation. Here the parents learn how to communicate with their deaf children.
        Children with disabilities are unfortunately still neglected, among other things because the parents and relatives do not know how to communicate with them.
        </div> 
        <div className='fs-9 mb-3'>
        In collaboration with kind donors based in Addis Ababa, the foundation has also donated books and toys in the value of 100,000 ETB which the school was desperately lacking.
        </div>    
      </div>
      <div className='col-sm-6'>
      <img src={fo3} alt="Logo" className='w-100'/> 

      </div>
      
    
    </div>
    <div className='container row m-auto'>
      <div className='col-sm-6'>
      <div className='fs-9'>
      For the new school year 2022/23 we are looking for partners to make it possible that 30 children can attend the school. The partnership can be made possible by financial donations to the school or by sponsoring individual students. This would guarantee on the one hand to relieve the families financially and on the other hand to ensure a continuous attendance for the whole school year.        </div>
      <div className='fs-9 my-3'>
      As a foundation, we would be happy to partner with you to cover these costs and provide a future for the children.</div>  
      </div>
      <div className='col-sm-6'>
      <img src={fo9} alt="Logo" className='w-100'/> 

      </div>
       
      <div className='d-flex w-100 m-auto'>
        <a href='https://chapa.link/donation/view/DN-PDSCuQCG2vi3' className='m-auto d-block'>
                              <div className='b-blue c-white px-3 py-2 text-center br-5p w-fit my-2'>Donate Now</div>
        </a>
      </div>
       </div>
      
  </div>
  )

}

export default Hosanna
