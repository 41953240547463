import React from 'react'
import '../../App.css';
import coming from '../../coming-soon2.gif';

function Contact() {
  return (
    <div className='100vh'>
      <div className='container'>
        <h1 className='c-primary pt-2p mt-9p'>Environment</h1>
        <img src={coming} alt="Logo" className=' h-100 d-block m-auto w-100-cu-sm'/>
      </div>
    </div>
  )
}

export default Contact