import React from 'react'
import eye1 from '../eye1.jpg';
import eye2 from '../eye2.jpg';
import eye3 from '../eye3.jpg';
import eye4 from '../eye4.jpg';
import eye5 from '../eye5.jpg';
import eye6 from '../eye6.jpg';
import eye7 from '../eye7.jpg';
import eye8 from '../eye8.jpg';
import eye9 from '../eye9.jpg';
import OwlCarousel from 'react-owl-carousel';
import { useState } from "react";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';



const Hosanna = () => {
  const [basicModal1, setBasicModal1] = useState(false);
  const toggleShow1 = () => setBasicModal1(!basicModal1);
  
  
  return (
    <div>
  <div className='row mt-9p section-full container mx-auto'>
    <div className='col-sm-6'>
      <div className='category-home mt-3 mb-1 h-fitcont '>Health</div>

      <h1 className='c-red text-left fw-semibold ff-goth'>SECONDARY SCHOOL EYE CARE</h1>
      <div className='c-primary ff-goth '>SPONSORSHIP 2022/23 SCHOOL YEAR</div>
      <div className='mt-4 fs-9'>Signum Vitae Comprehensive Eye Care Cooperative was established in 1989 in Addis Ababa, initially as an NGO to offer vision health services to those in need and working opportunities to the disabled. The work began by training men and women in different fields, such a woodwork, making frames for eyeglasses, grinding lenses, and optical work.<br className='mb-1'></br>

Starting from 2018 has been doing free treatments consisting of eye check ups, frame prescriptions and frames for 20 students of Yeka Tesfa Berhan Secondary School. <br className='mb-1'></br>

In the following years request for the treatment grew not only in the school but also the surrounding schools. It was this explosive demand that inspired Signum Vitae to reach out to the Dr. Negaso Gidada Foundation for Sponsorship opportunities.<br className='mb-1'></br>

Since 2021 the foundation covers the cost of check ups and frames for 130 students while Signum Vitae covers the lens costs. Students are currently coming from Edget Chora Secondary School and Yetesfa Birhan Secondary School in Addis Ababa. <br className='mb-1'></br>

We hope to expand the program to reach many more children in need of treatment as this prevents them from missing lessons or dropping out due to undiagnosed eye problems.</div>
      
                        <a href='https://chapa.link/donation/view/DN-biH7bLICHlQ0'>
                            <div className='b-blue c-white px-3 py-2 text-center br-5p w-fit my-2'>Donate Now</div>
                        </a>
  
    </div>
    <div className='col-sm-6'>
      <img src={eye1} alt="Logo" className='w-100 my-3 mt-5'/> 

    </div>
    </div>
  </div>
  )

}

export default Hosanna
