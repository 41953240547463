import React from 'react'
import '../../App.css';
import background from '../../drloc.PNG';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faMapMarker, faPhone, faEnvelope, faPhoneRotary, faPhoneVolume }from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faInstagram, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';

// import {faMapMarker} from '@fortawesome/free-brands-svg-icons';

function Contact() {
  window.onload = function(){ 
  var link = document.getElementById('email');
  

link.onclick = function() {
    var name = "info";
    var domain = "drnegasogidada.org";
    var linker = "mailto:" + name + '@' + domain + "?subject=Contact Us&body=";
    linker += getBody();
    link.setAttribute("href", linker);
};

function getBody() {
  var body = document.getElementById('body');
  const value2 = body?.value || '';
    return value2;
}
};

  return (
    <div className='row mt-c-4 pt-6p min-h70'>
      <div className='col-sm-10 px-cus-9 my-5'>
        <h2 className='text-left fw-700 c-primary mb-2'>Contact Us</h2>
        <div className='fs-6 w-70p w-100-cu-sm'>Feel free to contact us anytime. We will get back to you as soon as we can.</div>
        <div>
          <form>
            <input placeholder='Name' name='name' className='contact-input my-3 w-50 py-2 w-100-cu-sm'/>
            <input name='email' placeholder='Email' type="email"  className='contact-input my-3 w-50 py-2 w-100-cu-sm' required />
            <input id="body" name='body' placeholder='Message' className='contact-input my-3 w-50 py-2 w-100-cu-sm' required/>
          </form>
          <a href="#" id='email' className='br-5p d-block fw-700 my-4 px-5 py-2 sec-button text-center border-none c-white'>Send</a>

        </div>
      </div>
      <div className='col-sm-2 b-red positon-relative mb-cus-sm-3'>
        <div className='b-blue position-absolute px-4 py-5 c-pos pos-init-im'>
          <h3 className='fw-700 c-white'>Info</h3>
          <div className='c-white'><span className='mr-5'><FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon></span>info@drnegasogidada.org</div>
          <div className='c-white'><span className='mr-5'><FontAwesomeIcon icon={faPhoneVolume}></FontAwesomeIcon></span> +25111834-27-62</div>
          <div className='c-white'><span className='mr-5'><FontAwesomeIcon icon={faPhone}></FontAwesomeIcon></span>+251903-77-77-79/ +251911-46-14-97</div>
          <div className='c-white'><span className='mr-5'><FontAwesomeIcon icon={faMapMarker}></FontAwesomeIcon></span>Old Airport, Bisrate Gabriel Area, AA, ET</div>
        </div>
        
      </div>
    </div>
    
  )
}

export default Contact