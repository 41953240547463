import React from 'react'
import { Link } from 'react-router-dom';
import africa from '../../book1.jpeg';
import addis from '../../book2.jpeg';
import tigri from '../../tigri.svg';



const Aboutn = () => {
  return (
    <div className='px-5 sticky-my pt-2p vh100'>
      <h1 className='c-primary mb-5 mt-5 fs-4rem'>Books</h1>
      <div className='row mb-5 container'>
        <div className='col-sm-4 h-100'>
          <div className='h-100 py-4 rounded box-s-ar px-3'>
          
              <img src={africa} alt="Logo" className='cu-book-img'/>
              <h5 className='c-primary mt-2'>ታሪክ አገርና ሕገ መንግስት</h5>
              <div className='fs-75'>Abrehot Ethiopia publisher plc.</div>
              <a href="tel:+251 0966 060708" className='fs-75'>Tel:- +251 0966 060708</a >
          </div>
        </div>
        <div className='col-sm-4 h-100'>
          <div className='h-100 py-4 rounded box-s-ar px-3'>
              <img src={addis} alt="Logo" className='cu-book-img'/>
              <h5 className='c-primary mt-2'>ዳንዲ የነጋሶ መንገድ</h5>
              <div className='fs-75'>Abrehot Ethiopia publisher plc.</div>
              <a href="tel:+251 0966 060708" className='fs-75'>Tel:- +251 0966 060708</a >      
          </div>
        </div>
       
      </div>
   </div>
  )
}

export default Aboutn