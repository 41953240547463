import React from 'react'
import Current from '../Current'
import Past from '../Pastc'


const Media = () => {
  return (
    <div>
      <Current />
      <Past />
    </div>
  )
}

export default Media