import React from 'react'
import { Link } from 'react-router-dom';
import play from '../iconsplay.png'

const MissionVision = () => {
  return (
    <div className='mt-5'>
        <h2 className='c-primary fw-semibold text-center ff-goth'> DR. NEGASO GIDADA FOUNDATION</h2>
        {/* <div className="text-center ab-desc">The Dr. Negaso Gidada Foundation was founded by his family and aims to serve as a living memorial of his work and values.</div> */}
        {/* <div className='ab-container d-flex justify-content-between align-items-center d-block-cu-sm my-cus-sm-5'>
            <div className='b-blue c-white text-center circle-stat w-23 mx-cu-auto'>
                <div className='tr-20'>
                    <div className='fs-1 ff-b'>5+</div>
                    <div className='fw-700'>Years of <br></br> experiance</div>
                </div>
            </div>
            <div className='hands-circle w-50 mx-cu-auto w-100-cu-sm'>
                <div>
                    <img src={hands} alt="Logo" className='hands-about'/>
                </div>
            </div>
            <div className='b-red  c-white text-center circle-stat w-23 mx-cu-auto'>
                <div className='tr-20'>
                    <div className='fs-1 ff-b'>5+</div>
                    <div className='fw-700'>Total <br></br> expenditure</div>
                </div>  
            </div>
        </div> */}
        <div className='ab-container text-center'>
        The Dr. Negaso Gidada Foundation was established in May 2019, a month after H.E Dr.Negaso Gidada, the first President of Federal Democratic Republic of Ethiopia, passed away. The Foundation is considered a living memorial focused on the legacy, writings and teachings of His Excellency. It aims to carry forward his vision and aspirations for the country and its' people whom he served tirelessly until his last breath.
        </div>
        <div className='row ab-container'>
            <div className='col-sm-6 mb-cu-sm-3 text-center'>
                <h4 className='c-primary'>Our Mission</h4>
                <div className='fs-9'>To work with marginalized community groups in order to end poverty and prevent abuses of democratic and human rights thereby enabling the achievement of self-reliance and sustainable development.</div>
            </div>
            <div className='col-sm-6 text-center'>
                <h4 className='c-primary'>Our Vision</h4>
                <div className='fs-9'>Aspire to see a country in which every citizen attains full democratic and human rights and has the opportunity to achieve full potential to overcome poverty and social injustice in order to live in dignity and security.</div>
            </div>
        </div>
        <Link to='about' className='text-decoration-none '>
            <div className='position-relative mb-2 w-12 m-auto mt-3'>
                            <div className='bt-donate px-3 py-2 text-center position-relative  w-fit '>Read More</div>
            </div>
        </Link>
        {/* <div className='h-40vh back-img c-white my-5'>
            <div className='row align-items-center justify-content-between py-5 px-5 h-40vh px-sm-cu-0'>
                <div className=' col-9 px-5 '>
                    <h6 className=''>Watch our latest campaign</h6>
                    <h1 className='text-left '>Hosanna School for the <br></br>deaf</h1>
                    <Link to='media'><h6 className='red-underline'>View all campaigns</h6></Link>
                </div>
                <div className='col-3'>
                    <img src={play} alt="Logo" className='h-65r y-pulse'/>

                </div>
            </div>
           
        </div> */}

    </div>
  )
}

export default MissionVision