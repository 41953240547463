import React from 'react'
import { Link } from 'react-router-dom';
import africa from '../../allafrica-logo-1.png';
import addis from '../../addisfortunelogo.jpg';
import tigri from '../../tigri.svg';



const Aboutn = () => {
  return (
    <div className='px-5 sticky-my pt-2p vh100 px-sm-0'>
      <h1 className='c-primary mb-5 mt-5 fs-4rem'>Videos</h1>
      <div className='row mb-5 container'>
        <div className='col-sm-4 h-100'>
          <div className='h-100 py-4 rounded box-s-ar px-3'>
              <iframe width="100%" height="200" src="https://www.youtube.com/embed/0wBpa6lKCfU" title="ዶ/ር ነጋሶ ጊዳዳ ጳጉሜን 2009 ዓ.ም ከፋና ብሮድካስቲንግኮርፖሬት ጋር ያደረጉት ቃለ ምልልስ  ክፍል- 2" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
              <div className='category-home my-2 h-fitcont mt-4'>Fana Television</div>
              <h5 className='c-primary'>ዶ/ር ነጋሶ ጊዳዳ ጳጉሜን 2009 ዓ.ም ከፋና ብሮድካስቲንግኮርፖሬት ጋር ያደረጉት ቃለ ምልልስ ክፍል- 2</h5>
            
          </div>
        </div>
        
        <div className='col-sm-4 h-100'>
          <div className='h-100 py-4 rounded box-s-ar px-3'>
            <iframe width="100%" height="200" src="https://www.youtube.com/embed/2aMZeSsCl3M" title="Dr. Negaso Gidada 1995 Presidential Election in Ethiopian Parliament" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>            <div className='category-home my-2 h-fitcont mt-4'>Dr. Negaso Gidada Foundation</div>
            <h5 className='c-primary'>Dr. Negaso Gidada 1995 Presidential Election in Ethiopian Parliament</h5>
  
        </div>
        
        </div>
        <div className='col-sm-4 h-100'>
          <div className='h-100 py-4 rounded box-s-ar px-3'>
              <iframe width="100%" height="200" src="https://www.youtube.com/embed/LLtCSPFBwic" title="የዶ/ር ነጋሶ ጊዳዳ ስርዓተ ቀብር / Dr. Negaso Gidada Funeral" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>              <div className='category-home my-2 h-fitcont mt-4'>MaledaTV Tube</div>
              <h5 className='c-primary'>የዶ/ር ነጋሶ ጊዳዳ ስርዓተ ቀብር / Dr. Negaso Gidada Funeral</h5>
          </div>
        </div>
      </div>
      <div className='row mb-5 container'>
        <div className='col-sm-4 h-100'>
          <div className='h-100 py-4 rounded box-s-ar px-3'>
              <iframe width="100%" height="200" src="https://www.youtube.com/embed/wXiw6i-AEbc" title="ጋዜጠኛ አርያ ተስፋ ማርያም እና የነጋሶ ጊዳዳ ልጅ ያወጡት ሚስጥር Ethiopia Araya Tesfamariam | Negasso Gidada" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>              <div className='category-home my-2 h-fitcont mt-4'>Fikre Selam</div>
              <h5 className='c-primary'>ጋዜጠኛ አርያ ተስፋ ማርያም እና የነጋሶ ጊዳዳ ልጅ ያወጡት ሚስጥር Ethiopia Araya Tesfamariam | Negasso Gidada</h5>
          </div>
        </div>
        <div className='col-sm-4 h-100'>
          <div className='h-100 py-4 rounded box-s-ar px-3'>
            <iframe width="100%" height="200" src="https://www.youtube.com/embed/FzonTpSZmtk" title="A Constitution for a Multinational Democratic State-Nation: The Case of Ethiopia" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>            <div className='category-home my-2 h-fitcont mt-4'>National Endowment for Democracy</div>
            <h5 className='c-primary'>A Constitution for a Multinational Democratic State-Nation: The Case of Ethiopia</h5>
        </div>
        </div>
      </div>
   </div>
  )
}

export default Aboutn