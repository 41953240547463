import '../App.css';
import './HeroSection.css';
import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import ProgressBar from 'react-bootstrap/ProgressBar';
function HeroSection() {
  const location= useLocation()

  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);
 
  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener('resize', showButton);

  return (
    <div className={location.pathname=="/"? "" : ""}>
      <div className='row'>
        <div className='col-12 mt-5' >
          <div id='parent' className='w-100 hero-container'>
          </div>
        </div>
      </div>
      {/* <div id="parent">
        <div className='hero-container mt-5'>
        </div>
        
      </div> */}
  
    </div>
   
    
  );
}

export default HeroSection;
