import React from 'react'
import b2 from '../san8.JPG';
import b3 from '../eye.jpg';
import b4 from '../IMG_8073.JPG';
import fo from '../fo1.JPG';

import { Link } from 'react-router-dom';

function Past() {
  
  return (
    <div>
        <h2 className='c-primary  mt-5 ff-goth text-center fs-1'>All Campaigns</h2>
        <div className="text-center ab-desc">Here you'll find the campaigns we had raised for using our foundation.</div>
        <div className='row ab-container'>
            
                <div className='col-pa-6 past-container p-0 h-100 mr-2 mb-cu-sm-1 br-5p'>
                <Link to='hossana' className='text-decoration-none '>
                    <img src={b4} alt="Logo" className='w-100 h-30vh past-img'/> 
                    <div className='d-flex justify-content-between'>
                        <div className=' b-blue c-white py-1 w-100 px-3 fs-9'>
                            Goal to be raised: 33,000USD
                        </div>
                    </div>
                    <div className='px-3 my-4 h-100'>
                        <div className='category-home my-2'>Health</div>
                        <h4 className='fw-700'>HOSANNA SCHOOL FOR THE DEAF</h4>
                        <div className='fs-75'>Sponsorship of students attending the boarding school.</div>
                        
                    </div>
                    </Link>
                </div>
            
           
                <div className='col-pa-6 past-container p-0 h-100 mb-cu-sm-1 br-5p'>
                <Link to='eyecare' className='text-decoration-none '>
                    <img src={b3} alt="Logo" className='w-100 h-30vh past-img'/> 
                    <div className='d-flex justify-content-between'>
                        <div className=' b-blue c-white py-1 w-100 px-3 fs-9'>
                            Goal to be raised: 11,000USD 
                        </div>
                    </div>
                    <div className='px-3 my-4 h-100'>
                        <div className='category-home my-2'>Health</div>
                        <h4 className='fw-700'>SECONDARY SCHOOL EYE CARE</h4>
                        <div className='fs-75'>A cooperation with Signum Vitae Comprehensive eye care to sponsor eye check ups and frames of Secondary School students in Addis Ababa. </div>
                    
                    </div>
                    </Link>

                </div>
        </div>
        <div className='row ab-container'>
                <div className='col-pa-6 past-container p-0 h-100 mr-2 mb-cu-sm-1 br-5p'>
                <Link to='sanitary' className='text-decoration-none '>

                    <img src={b2} alt="Logo" className='w-100 h-30vh past-img'/> 
                    <div className='d-flex justify-content-between'>
                        <div className=' b-blue c-white py-1 w-100 px-3 fs-9'>
                            Goal to be raised: 22,000USD
                        </div>
                    </div>
                    <div className='px-3 my-4 h-100'>
                        <div className='category-home my-2'>Health</div>
                        <h4 className='mx-3 fw-700'>EDUCATIONAL & SANITARY MATERIAL SUPPORT</h4>
                        <div className='fs-75 mx-3'>Supporting students of the Dil Betigil Primary School in Dembi Dollo with educational and sanitary material.</div>
                        
                    </div>
                    </Link>

                </div>
                <div className='col-pa-6 past-container p-0 h-100 mb-cu-sm-1 br-5p'>
                <Link to='forthedeaf' className='text-decoration-none '>

                    <img src={fo} alt="Logo" className='w-100 h-30vh past-img'/> 
                    <div className='d-flex justify-content-between'>
                        <div className=' b-blue c-white py-1 w-100 px-3 fs-9'>
                            Goal to be raised: 33,000USD
                        </div>
                    </div>
                    <div className='px-3 my-4 h-100'>
                        <div className='category-home my-2'>Health</div>
                        <h4 className='mx-3 fw-700'>CO-ACTION PRE-SCHOOL FOR THE DEAF </h4>
                        <div className='fs-75 mx-3'>Sponsorship of breakfast and lunch for students and staff. Sponsorship of parent & teacher training.Sponsorship of students’ transport and uniforms.</div>
                        
                    </div>
                    </Link>

                </div>
        </div >
        <a class="text-decoration-none mb-3" href="/campaign">
            <div class="position-relative mb-2 w-18 m-auto ">
                <div class="text-center  px-2 d-block py-2 b-white ml-5 b-sm-cu bo-blue w-fit">View All Campaigns</div>
            </div>
        </a>
    </div>
  )
}

export default Past