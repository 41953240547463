import React from 'react';
import '../../App.css';
import Cards from '../Cards';
import About from '../About';
import HeroSection from '../HeroSection';
import Footer from '../Footer';
import MissionVision from '../MissionVision';
import Partners from '../Partners';
import Past from '../Past';
import Current from '../CurrentHome';
import Testmonials from '../Testmonials';


function Home() {
  return (
    <>
      <HeroSection />
      <MissionVision />
      <Past />
      {/* <Current /> */}
      {/* <Testmonials /> */}
      <Partners />
      
    </>
  );
}

export default Home;
