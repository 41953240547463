import React from 'react'
import san1 from '../san1.JPG';
import san2 from '../san2.JPG';
import san3 from '../san3.JPG';
import san4 from '../san4.JPG';
import san5 from '../san5.JPG';
import san6 from '../san6.JPG';
import san7 from '../san7.JPG';
import san8 from '../san8.JPG';
import san9 from '../san9.JPG';
import { useState } from "react";





const Hosanna = () => {
  const [basicModal1, setBasicModal1] = useState(false);
  const toggleShow1 = () => setBasicModal1(!basicModal1);
  
  
  return (
    <div>
  <div className='row mt-9p container mx-auto align-items-center mb-2'>
    <div className='col-sm-6'>
      <div className='category-home mt-3 mb-1 h-fitcont'>Health</div>

      <h1 className='c-red text-left fw-semibold ff-goth'>EDUCATIONAL & SANITARY MATERIAL SUPPORT</h1>
      <div className='c-primary ff-goth'>SPONSORSHIP 2022/23 SCHOOL YEAR</div>
      <div className='mt-4 fs-9'>
      <div className='mb-1'>Located 652km South West of Addis Ababa in Kellem Wollega Zone of Oromia Regional State, Dilbetigeil Primary School educates 1,736 pupils from grades 1-8. Of these students, the project focuses on 50 children from severely disadvantaged households supporting them with educational, body hygiene and cleaning materials.</div>
      <div className='mb-1'>The project started in 2020 with a donation of 200,000 ETB from the foundation to facilitate these needs. The project has since continued with great success and hopes to expand to support more children.</div>
      <div className='mb-1'>A major objective is to contribute to the national goal of Universal Primary Education (UPE) for all. The project aims to reach orphaned children and other children coming from disadvantaged households deprived of an education.</div>
      <div className='mb-1'>Assessment of the target area revealed majority of the households, of which half are female-headed, are without regular income. Most of the women depend on small petty trade, by selling the local injera flatdivead and vegetables in small local markets. Furthermore the assessment revealed that the income level of the majority of the community is too low to fulfil the basic needs of food security, medication and education.</div>
      
      </div>
     
      <a href='https://chapa.link/donation/view/DN-26onqW0vkVMq' >
                            <div className='b-blue c-white px-3 py-2 text-center br-5p w-fit my-2'>Donate Now</div>
                        </a>
    
    </div>
    <div className='col-sm-6'>
    <img src={san9} alt="Logo" className='w-100 my-3 mt-5'/> 

   </div>
    </div>
    <div className='row container m-auto'>
      <div className='col-sm-6'>
      <div className='mb-1'>There is acute income shortage and inability to cover initial school materials and fees, as well as sanitary materials. This resulting in children from low income households, particularly orphaned children to have higher drop out rates. In fact the overall enrolment from this segment of society is alarmingly low.</div>
      <div className='mb-1'>In addition to facing the adversities of poverty, orphans and children stemming from low income families face stigma from the community. This stigma causes the children shame to mingle with other students and they are thus less motivated to stay in school.</div>
      <div className='mb-1'>We are working with the community to eradicate this stigma and backward notion that these children are somehow less than the other children in the society.</div>
      <div className='mb-1'>The current conflict in Oromia zone has been detrimental to Dembi Dollo causing an explosion of IDPs and children in need. The communities are desperate for help now so we have decided to increase our campaign goal so as to reach more people.</div>
      <div className='fs-9 mt-4'>
      Our previous contribution of 200,000 ETB allowed us to provide 50 children with:
      <div className='row'>
        <div className='col-6'>
        <ul>
        <li>Uniforms</li>
        <li>Exercise Books</li>
        <li>Pens</li>
      </ul>
        </div>
        <div className='col-6'>
        <ul>

        <li>Pencils</li>
        <li>School Bags - Soap</li>
        <li>Sanitary Pads</li>
      </ul>
        </div>
      </div>
      

      </div>
      </div>
      <div className='col-sm-6'>
      <img src={san8} alt="Logo" className='w-100'/> 

      </div>
    </div>
    <div className='d-flex w-100 m-auto'>
        <a href='https://chapa.link/donation/view/DN-26onqW0vkVMq' className='m-auto d-block'>
                              <div className='b-blue c-white px-3 py-2 text-center br-5p w-fit my-2'>Donate Now</div>
        </a>
      </div>
    </div>
  )

}

export default Hosanna
