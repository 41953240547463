import logo from '../logo.png';
import React, { useState, useEffect } from 'react';
import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBIcon,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBBtn,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBCollapse,
} from 'mdb-react-ui-kit';
import { Link } from 'react-router-dom';


function MyNavbar() {

    

  const[color, setColor] =useState(false)
  const changeColor =() =>{
    if(window.scrollY >= 90){
      setColor(true)
    }else{
      setColor(false)
    }
  }
  window.addEventListener('scroll', changeColor)
 
  const [showBasic, setShowBasic] = useState(true);

  

  return (

    <MDBNavbar expand='lg' className={color ? "b-white px-5 position-fixed w-100vw" :'nav-background  px-5 position-fixed w-100vw' }>
    <MDBContainer fluid className=''>
      <MDBNavbarBrand href='/'><img src={logo} alt='logo' className='h-50px'></img></MDBNavbarBrand>

      <MDBNavbarToggler
        aria-controls='navbarSupportedContent'
        aria-expanded='false'
        aria-label='Toggle navigation'
        onClick={() => setShowBasic(!showBasic)}
      >
        <MDBIcon icon='bars' fas />
      </MDBNavbarToggler>

      <MDBCollapse navbar show={showBasic}>
        <MDBNavbarNav className=''>
          <MDBNavbarItem>
            <MDBNavbarLink active aria-current='page' href='/'>
              Home
            </MDBNavbarLink>
          </MDBNavbarItem>
          <MDBNavbarItem>
            <MDBNavbarLink href='/campaign' >Campaign</MDBNavbarLink>
          </MDBNavbarItem>

          <MDBNavbarItem>
            <MDBDropdown>
              <MDBDropdownToggle tag='a' className='nav-link'>
                About
              </MDBDropdownToggle>
              <MDBDropdownMenu>
                <MDBDropdownItem link href='/about'>About the Foundation</MDBDropdownItem>
                <MDBDropdownItem link href='/aboutn'>About Dr Negaso</MDBDropdownItem>
              </MDBDropdownMenu>
            </MDBDropdown>
          </MDBNavbarItem>
          <MDBNavbarItem>
            <MDBDropdown>
              <MDBDropdownToggle tag='a' className='nav-link'>
                Archives
              </MDBDropdownToggle>
              <MDBDropdownMenu>
                <MDBDropdownItem link href='/articles'>Articles</MDBDropdownItem>
                <MDBDropdownItem link href='/videos'>Videos</MDBDropdownItem>
                <MDBDropdownItem link href='/books'>Books</MDBDropdownItem>
                <MDBDropdownItem link href='/interviews'>Interviews</MDBDropdownItem>
                <MDBDropdownItem link href='/lectures'>Lectures</MDBDropdownItem>

              </MDBDropdownMenu>
            </MDBDropdown>
          </MDBNavbarItem>
          <MDBNavbarItem>
            <MDBDropdown>
              <MDBDropdownToggle tag='a' className='nav-link'>
                Our work
              </MDBDropdownToggle>
              <MDBDropdownMenu>
                <MDBDropdownItem link href='/community'>Community Stories</MDBDropdownItem>
                <MDBDropdownItem link href='/environment'>Environment</MDBDropdownItem>
                <MDBDropdownItem link href='/education'>Education</MDBDropdownItem>
                <MDBDropdownItem link href='/health'>Health</MDBDropdownItem>
                <MDBDropdownItem link href='/rndd'>Research & Dialogue</MDBDropdownItem>
              </MDBDropdownMenu>
            </MDBDropdown>
          </MDBNavbarItem>
          <MDBNavbarItem>
            <MDBNavbarLink href='/contact'>Contact</MDBNavbarLink>
          </MDBNavbarItem>

         
        </MDBNavbarNav>

       <Link to="/campaign">
              <div className='position-relative mb-2 mx-sm-cu-3'  >
                        <div className='b-blue c-white px-3 py-1 text-center br-5p '>Donate</div>
              </div>
        </Link>
      </MDBCollapse>
    </MDBContainer>
  </MDBNavbar>
  
     
      
  );
}

export default MyNavbar;
