import React from 'react'
import hoss1 from '../hoss1ad.jpeg';
import hoss2 from '../hoss2.JPG';
import hoss3 from '../hoss2ad.jpeg';
import hoss4 from '../hoss3ad.jpeg';
import hoss5 from '../hoss5.JPG';
import hoss6 from '../hoss4ad.jpeg';
import hoss7 from '../hoss7.JPG';
import hoss8 from '../hoss8.JPG';
import hoss9 from '../hoss9.JPG';
import hoss10 from '../hoss10.JPG';
import hoss13 from '../hoss13.JPG';
import { useState } from "react";




const Hosanna = () => {
  const [basicModal1, setBasicModal1] = useState(false);
  const toggleShow1 = () => setBasicModal1(!basicModal1);
 
  return (
    <div>
  <div className='row mt-9p  container mx-auto'>
    <div className='col-sm-6'>
      <div className='category-home mt-3 mb-1 h-fitcont'>Health</div>

      <h1 className='c-red text-left fw-semibold ff-goth'>HOSANNA SCHOOL FOR THE DEAF</h1>
      <div className='c-primary ff-goth'>SPONSORSHIP 2022/23 SCHOOL YEAR</div>
      <div className='mt-4 fs-9'>Ethiopian Evangelical Church Mekane Yesus (EECMY)-Development and Social Service Commission (DASSC) Hosanna School for the Deaf was established in 1981. Located around 230 Kilometer South from Addis Ababa, the original notion for the school came from Gunar Gomer, former teacher for the deaf in Keren, Eritrea. The Christofell Blind Mission (CBM) from Germany and the Deaf African Mission (DAM) from Sweden were among the first organizations to support the establishment of the school. The Finnish Evangelical Lutheran Mission (FELM) also started to send experts and personnel during the first critical startup years of the school. Currently, the school provides both academic and vocational education from preschool to grade 12 and Technical Vocational and educational training (TVET) for 220 students, while providing bedding, food and accommodation services for hearing impaired students coming from all over the country.</div>
      <a href='https://chapa.link/donation/view/DN-Rxy00VV3hvaC'>
                            <div className='b-blue c-white px-3 py-2 text-center br-5p w-fit my-2'>Donate Now</div>
                        </a>
    </div>
    <div className='col-sm-6'>
      <img src={hoss2} alt="Logo" className='w-100'/> 
    </div>
  </div>
  <div className='container m-auto my-5 row'>
    <div className='col-sm-6'>
      <div className=''>
          <h4 className='c-primary'>1. Mission</h4>
          <div className='fs-9'>To see that all hearing impaired children in the country have access to quality education at every level. To be able to lead their life independently and to enable them to contribute for their country same as other citizens.</div>
        </div>
        <div className='my-3 '>
          <h4 className='c-primary'>2. Vision</h4>
          <div className='fs-9'>Facilitating conditions and giving support to hearing impaired students to learn academic and vocational education up to a higher level.
      Providing in-service training for all the teachers of the hearing impaired in Ethiopia; to improve methods of teaching and sensitising the decision making bodies to facilitate conditions for all hearing impaired children thereby ensuring access and quality education in their language (sign language).
      Conducting research and developing sign language to introduce new skills as “Best Practice” across Ethiopia</div>
        </div>
        <div className=''>
        <h4 className='c-primary'>3. Objective</h4>
        <div className='fs-9'>To provide academic education and vocational skill training. Provide Technical, Vocational & Educational Training (TVET) from level one to three for hearing impaired children. Capacity building training for teachers who teach</div>
        </div>
    </div>
    <div className='col-sm-6'>
    <img src={hoss5} alt="Logo" className='w-100'/> 
    </div>
      
      
  </div>
  <div className='row container mx-auto'>
    <div className='col-sm-3'>
    <img src={hoss1} alt="Logo" className='w-100'/> 

    </div>
    <div className='col-sm-3'>
    <img src={hoss3} alt="Logo" className='w-100'/> 

    </div>
    <div className='col-sm-3'>
    <img src={hoss4} alt="Logo" className='w-100'/> 

    </div>
    <div className='col-sm-3'>
    <img src={hoss6} alt="Logo" className='w-100'/> 

    </div>

  </div>
    <div className='m-auto d-flex w-100'>
  <a href='https://chapa.link/donation/view/DN-Rxy00VV3hvaC' className='m-auto d-block'>
                            <div className='b-blue c-white px-3 py-2 text-center br-5p w-fit my-2'>Donate Now</div>
                        </a></div>
 
</div>
  )

}

export default Hosanna
