import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import hands from '../../writing.png';
import dr from '../../drneg.jpeg';
import africa from '../../africa.png';
import pope from '../../withpop.jpeg';
import haile from '../../withhaile.png';
import smile from '../../old.png';


const Aboutn = () => {
  return (
    <div className='px-5 sticky-my pt-2p'>
      <div className='row my-5 '>
        <div className='col-sm-2'>
          <div className='my-5 fw-700'>A brief history</div>
        </div>
        <div className='col-sm-10'>
          <h1 className='text-left c-primary fw-700'>About His Excellency, <span className='c-red'> Dr.Negaso Gidada </span></h1>
          <div className='my-2'>
          His Excellency Dr. Negaso Gidada Solan was born on Pagume 3, 1935 E.C (September 8, 1943) in the city of Dembi Dollo , Wollega - Oromia Western Ethiopia to Reverend Gidada Solan and his mother Mrs. Dinse Sholi. His father, one of the founders of the Bethel Church, was known for his strength and principle, despite becoming blind at the age of six. In a time that was uncomfortable for religious teachers, Reverend Gidada was one of the most outspoken clerics. Growing up under the care of such a person, Dr. Negaso learned much about the true meaning of strength.</div>
          <div>Dr. Negaso joined the Bethel School at Dembi Dollo. After some years, his father was transferred to Mizan Teferi (south Ethiopia) where he continued his education. For secondary school education, he joined Nazareth Bible Academy at Adama, where he pursued his studies up to grade eleven. He then took the entrance examination and placed first in his group, joining Baede Mariam preparatory school. At Addis Ababa University, he graduated with a BA in History in 1963 E.C (1971).

          </div>
        </div>

      </div>
      <div className='dr-back'></div>
      <div className='text-center mt-1 fs-75'>Young Dr. Negaso with his parents and siblings. From Left: Sr. Tsehaitu, Dr. Solomon, Mrs. Dhinsee Shoolii, Elsai, Dr. Negaso, Reverend Gidada , Rahel</div>
      <div className='row my-5 align-items-center'>
        <div className='col-sm-6'>
          <div>        After graduation, Dr. Negaso went to Western Wollega, and became the Lalo Ayira Secondary School Director from ’63 – ’66 E.C (1971 – 1974). In 1966 EC (1974) he left the country to study at the German Goethe University in the department of Ethnology up to Ph.D. level. 
</div>
<div className='my-2'>Dr. Negaso’s political philosophy was formed during his youth when he observed the way the traditional rulers were treating the communities in Mizan Teferi area in south Ethiopia, with political and administrative suppression. This situation created questions deep in his mind which followed him to Addis Ababa University and persuaded him to join the struggle waged by the elites. In his time at university, Dr. Negaso spoke about observing and later joining, the progressive youth who worked patiently to eliminate the systems of oppression and abuse.</div>
<div>Subsequently, he became an active participant in the student movement of the 60s, as they started raising their voice in various demonstrations. By learning and expanding their knowledge, the student movement believed strongly in the honour of fighting for the people.</div>
<div className='my-2'>While Dr. Negaso was pursuing his Post Graduate studies in Germany, he also participated in various organizations and rallies. He also made significant contributions in Oromo politics, and fought for the preservation and promotion of the Oromo language, traditions and values equally with other Ethiopian languages and cultures by conducting various research and studies. His deep respect, passion and concern for his people convinced him o be a member of political organizations where he waged non-stop struggle for his beliefs.
</div>

        </div>
        <div className='col-sm-6'>
          <img src={hands} className="w-100"></img>
        </div>
      </div>
      <div className='row my-5 align-items-center'>
        <div className='col-sm-6'>
          <img src={smile} className="w-100"></img>
        </div>
        <div className='col-sm-6'>
          <div className='mb-2'>        When the DERG administration fell, Dr. Negaso came back to Ethiopia in 1983 E.C (1991) to serve his country once more. He joined the Oromo People’s Democratic Organization (OPDO), which made up four of the coalitions of the then ruling party, Ethiopian People’s Revolutionary Democratic Front (EPRDF). 
</div>
<div className='mb-2'>During this time he was also a member of the Constitutional Drafting Committee, which was tasked to draft the present Constitution of the FDRE following the fall of the Marxist regime in 1991. H.E Dr. Negaso also served as Chairman of the Constitutional Assembly which adopted the current constitution. In a pioneering act, he would go on to sign the final copy of the constitution in 1995 which has remained intact until this day.
</div>
<div className='mb-2'>He served as Minister of Information and as Minister of the Labour and Social Affairs. After the new Constitution was endorsed in 1995, Dr. Negaso became the first democratically elected President of the Federal Democratic Republic of Ethiopia and served the people and his country for six years with honesty and integrity.
</div>
<div className='mb-2'>When Dr. Negaso realized that the party of his participation manifested standards contrary to his own political outlook, he resigned from the party and the presidential position after clearly expressing his views and beliefs, with determination and principle. He is the first person to ever do so, publicly declaring that enough is enough and going to the midst of the people to wage the struggle his own way until his passing. 
</div>


        </div>
      </div>
      <div className='row my-5 align-items-center'>
      <div className='col-sm-6 py-4'>
      
<div className='mb-2'>After his resignation as president, in 2005 H.E Dr. Negaso Gidada competed for a seat in the Ethiopian People’s House of Representatives at his constituency in his hometown Dambi Dollo and won the election with an overwhelming majority. Later, he joined an opposition political party called Unity for Democracy and Justice where he served as chair and co-founded the coalition called Medrek.
</div>
      <div className='mb-2'>      As punishment for disagreeing with the ruling party and competing for a seat on Parliament, he was stripped of his rights as an Ex-President. The ruling party took away his salary, household stipend, cars, security and made several attempts at evicting him from his family home amongst other things. However, these bullying methods and attempts at intimidation did not break his spirit as he continued to be a strong voice of opposition to injustice. The illegal action of stripping him of his Presidential rights has not been solved until this day. Although several attempts  were made to bring the matter to court and parliament. 
</div>

Dr. Negaso was a humble and selfless man, who worked tirelessly for the dignity of the communities. Whether as the country’s President, who lived in the palace with presidential protocol, or in line to take a local taxi, he remained a man of principle. Everyone who had the privilege of meeting him would say that he was a true Ethiopian who lived for the truth.

      </div>
      <div className='col-sm-6'>
        <img src={pope} className="w-100"></img>

      </div>
       
      </div>
      <div className='row align-items-center'>
        <div className='col-sm-6'>
        <img src={dr} className="final-img"></img>

        </div>
        <div className='col-sm-6'>
        <div className='container fst-italic'> 
        <h4 className='text-center'> Among his teachings, the following text is taken from writings:</h4>

<div className='mb-2'> <span className='c-red mr-3'>" </span>Be it my generation or the next, we must all learn from the past. The past generations have chosen their paths and laboured for it. And truly, they have had their own influence in bringing about political change and development.<span className=' c-red'>"</span></div>
<div className='mb-2'><span className=' c-red'>"</span>I don’t think its right to judge all that was done in the struggle to depose the monarch in todays standards, they have also influenced our today. This is not to say all they have done is right, it is not. But it is the responsibility of the present to learn from the pasts’ strength and folly moving forward. Now, this generation should deeply study the present and use the lessons to decide on what direction to take.<span className=' c-red'>"</span>
</div>
<div className='mb-2'><span className=' c-red'>"</span>Of course, not all roads are the same. You can never be completely sure you’re on the right path.  But if different options are presented, the people choose and work to realise the choice they made. You cannot use weapons to control the people and hope to reach the development the world has achieved.<span className=' c-red'>"</span> 
</div>
<div className='mb-2'><span className=' c-red'>"</span>I used to be envious when I saw the people choose their leaders in other countries. I used to think that this was what my country needs. This is why I came back and tried to bring change, and continue to do so. I know this world I believe in will come for my children’s generation. Because I am certain this generation will come, I implore you to be strong enough to achieve this as soon as possible. Be strong! We will also help you as long as we’re here. I believe without a doubt that the next generation will live better lives. I am sure of it.<span className=' c-red'>"</span>
</div>
<div className='mb-2'><span className=' c-red'>"</span>I hope that one day, our country will be prosperous for all its citizens. That it becomes a country where better societal, health and work-based insurance, (including its military and peace forces), better retirement plan, maternity, youth unemployment, insurance, better childbirth care etc.… will be created. I hope that the next generation of Ethiopians will live in a country where human rights are respected and democracy is guaranteed, a place where citizens will be able to speak their beliefs without restrictions, free from conspiracy and complications. <span className=' c-red'>"</span>
</div>
<div className='mb-2'><span className=' c-red'>"</span>My vision for Ethiopia is for it to be a free citizens country. A place where all their democratic and human rights are respected, where their power would not be limited in any way or situation, where they can express their thoughts in any language they want and receive any administrative, legal, social services in any place, where they have the right to learn in their mother tongue and more. Because the only way we can create the future we need is to nurture a generation of citizens who can think, say, support and object freely, and that can be done only through this democratic way of thinking. When Ethiopia becomes a country of free citizens, only then will we be able to nurture a generation that is conscious and loves its country.<span className=' c-red'>"</span> 
</div>
<div className='mb-2'><span className=' c-red'>"</span>My vision is creating an Ethiopia with people free from poverty and backward thinking. Free people will respect others freedom to protect their own. They will not feign ignorance to the violation of other people’s rights. Our country is one of religious people. All of our religions teach us to love our neighbors like we love ourselves. And I truly hope that Ethiopia becomes a country that can embody this golden rule.<span className=' c-red'>"</span></div>
</div>
        </div>


<div className='mt-3'>Dr. Negaso laboured to bring this golden message to fruition. He was known for his sincerity in his community, grieving with those who were grieving, and rejoicing with those who rejoiced. In his political career which lasted more than 50 years many will testify that he was a pure minded Ethiopian who stood his ground and worked without discrimination, with honesty and objectivity for Ethiopia and its people. 
</div>
<div>Dr. Negaso Gidada Solan, who had gone to Germany for medical treatment for unexpected health problems, passed away on 19. 08. 2011EC (27.04.2019). His work, however will continue to be a lesson of strength and humility for generations.
</div>
      </div>

   </div>
  )
}

export default Aboutn