import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import hands from '../random.jpg';


const About = () => {
  return (
    <div className='sticky-my'>
      <div className='row p-7rem my-5 align-items-end pt-2p'>
          <div className='col-sm-6'>
          
            <h2 className='c-blue fw-700 fs-4rem fs-sm-2rem mb-0'>About <span className='c-red'>Dr. Negaso Gidada</span> Foundation</h2>
          </div>
          <div className=' col-sm-6 fs-85'>The Dr. Negaso Gidada Foundation was established in June 2019, a few months after His Excellency Dr. Negaso Giadada passed away unexpectedly. It was founded by his family and aims to serve as a living memorial of his work and values. Additionally it aims to honour and carry on his unique legacy.
</div>
      </div>
      <div className='foundation-back '></div>
      {/* <hr className='mx-5 bt-3'></hr> */}
      <div className='row my-5 our-container'>
        <div className='col-sm-5'>
          <h1 className='ff-b text-left mb-sm-cus-1'>Our Story <span className=''><FontAwesomeIcon icon={faArrowRight} className='c-red rotate-45'></FontAwesomeIcon></span></h1>
        </div>
        <div className='col-sm-7'>
          <div className='px-4'>
            <span className='ff-b c-black'>The Dr. Negaso Gidada Foundation was founded by his family and aims to serve as a living memorial of his work and values. Additionally it aims to honour and carry on his unique legacy.</span><br></br>
              <div className='mt-2'>The Dr. Negaso Gidada Foundation was established in June 2019, a few months after His Excellency Dr. Negaso Giadada passed away unexpectedly.</div>
              <div className='mt-2'>H.E Dr. Negaso Gidada was the first democratically elected President of the Federal Democratic Republic of Ethiopia, who voluntarily stepped down from power shortly before starting his second term. This was due to disagreements with the then ruling party Ethiopia People’s Revolutionary Democratic Front of which he was also a member. </div>
              <div className='mt-2'>As punishment for disagreeing with the ruling party and resigning, he was stripped of his rights as an Ex-President. The party took away his salary, household stipend, cars, security and made several attempts at evicting him from his family home amongst other things. </div>
              <div className='mt-2'>However, H.E Dr. Negaso Gidada did not join politics for the material things, but rather for his belief in a better future for the Ethiopian people. The bullying methods of the ruling party did not intimidate him and though he consequentially faced many adversities, he continued to be a strong voice of opposition to injustice until his last day.</div>
          </div>
        </div>
      </div>
      <div className="row our-container">
            <div className="col-sm-6 p-5">
                <h3 className='c-primary'>Mission</h3>
                <p className=''>To work with marginalized community groups in order to end poverty and prevent abuses of democratic and human rights thereby enabling the achievement of self-reliance and sustainable development.</p>
            </div>
            <div className="col-sm-6 p-5 ">
                <h3 className='c-primary '>Vision</h3>
                <p>Aspire to see a country in which every citizen attains full democratic and human rights and has the opportunity to achieve full potential to overcome poverty and social injustice in order to live in dignity and security.</p>

            </div>
      </div>
      <div className='mb-5'>
        {/* <h1 className='fw-700 my-4'>Meet our <span className='c-red'> Team</span> </h1> */}
        {/* <div className='row our-container'>
          <div className='col-sm-3 mb-sm-cus-3'>
            <div><img src={hands} alt="Logo" className='w-100'/></div>
            <div className='fs-4 fw-700'>Jane Doe</div>
            <div>Founder</div>
          </div>
          <div className='col-sm-3 mb-sm-cus-3'>
            <div><img src={hands} alt="Logo" className='w-100'/></div>
            <div className='fs-4 fw-700'>Jane Doe</div>
            <div>Founder</div>
          </div>
          <div className='col-sm-3 mb-sm-cus-3'>
            <div><img src={hands} alt="Logo" className='w-100'/></div>
            <div className='fs-4 fw-700'>Jane Doe</div>
            <div>Founder</div>
          </div>
          <div className='col-sm-3'>
            <div><img src={hands} alt="Logo" className='w-100'/></div>
            <div className='fs-4 fw-700'>Jane Doe</div>
            <div>Founder</div>
          </div>
        </div> */}
      </div>

    </div>
   
  )
}

export default About