import React from 'react';
import MyNavbar from './components/Navbar';
import './App.css';
import Home from './components/pages/Home';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Services from './components/pages/Services';
import Contact from './components/pages/Contact';
import SignUp from './components/pages/Media';
import About from './components/pages/About';
import Media from './components/pages/Media';
import Footer from './components/Footer';
import Aboutn from './components/pages/Aboutn';
import Comm from './components/pages/Community';
import Env from './components/pages/Environment';
import Edu from './components/pages/Education';
import Health from './components/pages/Health';
import RndD from './components/pages/RndD';
import Articles from './components/pages/Articles';
import Videos from './components/pages/Videos';
import Books from './components/pages/Books';
import Interviews from './components/pages/Interviews';
import Lectures from './components/pages/Lectures';
import Hossana from './components/Hossana';
import Eyecare from './components/Eyecare';
import Sanitary from './components/Sanitary';
import Forthedeaf from './components/Forthedeaf';



const App = () => (
  <Router>
  <MyNavbar />
  <Routes>
    <Route path='/' exact element={<Home/>} /> 
    <Route path='/services' element={<Services/>} /> 
    <Route path='/about' element={<About/>} /> 
    <Route path='/aboutn' element={<Aboutn/>} /> 
    <Route path='/campaign' element={<Media/>} /> 
    <Route path='/contact' element={<Contact/>} /> 
    <Route path='/sign-up' element={<SignUp/>} /> 
    <Route path='/community' element={<Comm/>} />
    <Route path='/environment' element={<Env/>} /> 
    <Route path='/education' element={<Edu/>} /> 
    <Route path='/health' element={<Health/>} /> 
    <Route path='/rndd' element={<RndD/>} /> 
    <Route path='/articles' element={<Articles/>} /> 
    <Route path='/videos' element={<Videos/>} />
    <Route path='/books' element={<Books/>} /> 
    <Route path='/interviews' element={<Interviews/>} /> 
    <Route path='/lectures' element={<Lectures/>} />  
    <Route path='/hossana' element={<Hossana/>} />  
    <Route path='/eyecare' element={<Eyecare/>} />  
    <Route path='/sanitary' element={<Sanitary/>} />  
    <Route path='/forthedeaf' element={<Forthedeaf/>} />
    <Route path='/campaign/hossana' element={<Hossana/>} />  
    <Route path='/campaign/eyecare' element={<Eyecare/>} />  
    <Route path='/campaign/sanitary' element={<Sanitary/>} />  
    <Route path='/campaign/forthedeaf' element={<Forthedeaf/>} />
  </Routes>
  <Footer/>
  </Router>
);

export default App;
