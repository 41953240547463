import React from 'react'
import { Link } from 'react-router-dom';
import africa from '../../allafrica-logo-1.png';
import addis from '../../addisfortunelogo.jpg';
import tigri from '../../tigri.svg';



const Aboutn = () => {
  return (
    <div className='px-5 sticky-my pt-2p vh100'>
      <h1 className='c-primary mb-5 mt-5 fs-4rem'>Articles</h1>
      <div className='row mb-5 container'>
        <div className='col-sm-4 h-100'>
          <div className='h-100 py-4 rounded box-s-ar px-3'>
            <a href="https://allafrica.com/stories/200212010037.html" target="_blank">
              <img src={africa} alt="Logo" className=' h-50p w-100-cu-sm'/>
              <div className='category-home my-2 h-fitcont mt-4'>All Africa</div>
              <h5 className='c-primary'>Ethiopia: Dr. Negasso Gidada - New Dimensions in Statesmanship</h5>
              <div className='fs-75'>In most parts of our country there is this beautiful culture that the coining of names of individuals is made to carry a certain meaning referring to some state of affairs occurring around the time of birth of the individual. It...</div>
            </a>
          </div>
        </div>
        <div className='col-sm-4 h-100'>
          <div className='h-100 py-4 rounded box-s-ar px-3'>
            <a className='' href="https://addisfortune.net/interviews/negasso-gidada/" target="_blank">
              <img src={addis} alt="Logo" className='w-100 h-50p'/>
              <div className='category-home my-2 h-fitcont mt-4'>Addis Fortune</div>
              <h5 className='c-primary'>Negaso Gidada: Overwhelmed By Past Reflections</h5>
              <div className='fs-75'>He was the most important person twenty years ago, approving the landmark that signifies a chapter in the Country’s political history. Back from a very private life for almost twenty years in Germany, He had a lot to learn. Though ...</div>
            </a>
          </div>
        </div>
        <div className='col-sm-4 h-100'>
          <div className='h-100 py-4 rounded box-s-ar px-3'>
          <a className='' href="https://www.tigraionline.com/negassospaper.html" target="_blank">
            <img src={tigri} alt="Logo" className='w-100 h-50p'/>
            <div className='category-home my-2 h-fitcont mt-4'>Tigrai Online</div>
            <h5 className='c-primary'>Root Causes of Under-development in Ethiopia</h5>
            <div className='fs-75'>Negaso Gidada was Chair Person of the Constitution Assembly in 1994 and President of the Federal Democratic Republic of Ethiopia from 1995 to 2001 Since October of last year he is Member of the House of Peoples Representatives as...</div>
          </a>
        </div>
        </div>
      </div>
   </div>
  )
}

export default Aboutn