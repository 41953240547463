import React from 'react';
import './Footer.css';
import flogo from '../footerlogo.jpg';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';

function Footer() {
  return (
    <div className='footer-container py-3 row align-items-center justify-content-between mx-0'>
      <div className='col-sm-4'>
        <img src={flogo} className='w-100'/>
        <div className='fs-6 text-center my-2'>&copy; Copyright 2022, Dr.Negaso Gidada Foundation</div>
      </div>
      <div className='col-sm-2'>
          <div className='fw-700 fs-5 my-2'>Navigate</div>
          <div><Link to={"/"} className="textd-non c-white">Home</Link></div>
          <div><Link to="campaign" className="textd-non c-white">Campaign</Link></div>
          <div><Link to="about" className="textd-non c-white">About the Foundation</Link></div>
          <div><Link to="aboutn" className="textd-non c-white">About Dr. Negaso</Link></div>
          <div><Link to="contact" className="textd-non c-white">Contact</Link></div>
      </div>
      <div className='col-sm-3'>
        <div className='fw-700 fs-5 my-2'>Contact</div>
        <div>info@drnegasogidada.org</div>
        <div>+25111834-27-62</div>
        <div>+251903-77-77-79/+251911-46-14-97</div>
        <div>Old Airport, Bisrate Gabriel Area, Addis Ababa, Ethiopia</div>
      </div>
      <div className='col-sm-3'>
        <div className='fw-700 fs-5 my-2'>Follow us on our socials</div>
        <div className='d-flex'>
          <a href='https://www.facebook.com/DNGfoundation/'  target="_blank"> <FontAwesomeIcon icon={faFacebook} className='c-red fs-5 mx-3' /></a>
          <a href='#'  target="_blank"> <FontAwesomeIcon icon={faInstagram} className='c-red fs-5 mx-3' /></a>
          <a href='https://twitter.com/drnegasogidada'  target="_blank"> <FontAwesomeIcon icon={faTwitter} className='c-red fs-5 mx-3' /></a>
          <a href='https://www.youtube.com/channel/UCMJAjQiaArs7u1EefCXS5NQ'  target="_blank"> <FontAwesomeIcon icon={faYoutube} className='c-red fs-5 mx-3' /></a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
