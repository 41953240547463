import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import dembi from '../dembi.jpg';
import enad from '../enad.jpg';
import planet from '../planet.jpg';
import signum from '../signum.png';
const Partners = () => {
  return (
    <div className='mt-5'>
           <h1 className='c-primary ff-goth'>Our partners</h1>
      <OwlCarousel className='owl-theme my-3' items={4} margin={10} autoplay loop autoplayHoverPause >
            <div className='item '>
              <img src={dembi}/>
            </div>
            <div className='item '>
              <img src={enad}/>
            </div>
            <div className='item '>
              <img src={planet}/>
            </div>
            <div className='item '>
              <img src={signum}/>
            </div>
        </OwlCarousel>;
    </div>
  )
}

export default Partners