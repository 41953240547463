import React from 'react'
import About from '../About'
import History from '../History'
import Navbar from '../Navbar'
const about = () => {
  return (
    <div>
      <About />
      {/* <History /> */}
    </div>
  )
}

export default about