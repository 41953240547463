import React from 'react'
import { useState } from "react";
import b2 from '../IMG_8073.JPG';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlayCircle} from '@fortawesome/free-solid-svg-icons';
import {
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
  } from 'mdb-react-ui-kit';
import uuid from 'react-uuid';



const Current = () => {
    const [basicModal1, setBasicModal1] = useState(false);
    const toggleShow1 = () => setBasicModal1(!basicModal1);
  return (
    <div className='sticky-my'>
        <div className='align-items-center justify-content-between row mt-4 pt-5'>
            <div className='col-sm-7 px-cus-5 py-sm-cu-4 px-sm-cu-5'>
                <h6 class="c-red">Latest campaign</h6>
                <div className='d-flex'>
                    <h1 className='c-primary fw-700 text-left'>HOSANNA SCHOOL FOR THE DEAF</h1>
                    <div className='category-home mt-1 mx-2 h-fitcont'>Health</div>
            </div>
                <div className='fs-6'>
                Ethiopian Evangelical Church Mekane Yesus (EECMY)-Development and Social Service Commission (DASSC) Hosanna School for the Deaf was established in 1981. Located around 230 Kilometer South from Addis Ababa, the original notion for the school came from Gunar Gomer, former teacher for the deaf in Keren, Eritrea. ...
                </div>
                <div className='my-3'><span className='c-primary fs-2 fw-700'>33,000USD</span><span> to be Raised</span></div>
                
                <div className='d-flex position-relative'>
                <a href='https://chapa.link/donation/view/DN-Rxy00VV3hvaC'>
                            <div className='b-blue c-white px-3 py-2 text-center br-5p w-fit my-2'>Donate Now</div>
                        </a>
                    {/* <a href='#'>
                        <div className='c-primary fs-6 fw-700 mx-4 my-3'>

                            <span><FontAwesomeIcon icon={faPlayCircle} className='c-red pr-2' /></span>
                            Watch
                        </div>
                    </a> */}
                </div>

            </div>
            <div className='col-sm-5'>
                <img src={b2} alt="Logo" className='d-sm-none-cu w-100'/> 
            </div>

        </div>
    </div>
    
            // <NavLink className="d-block mx-auto outlined-btn" to="Current">Learn More</NavLink>

            
       
  )
}

export default Current